import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { LOCALIZATION, COUNTRIES, IS_FOREIGN_COUNTRY } from '../../utils/constants'
import IndexPage from '../../pages/index'
import StoreContext from '../../context/StoreContext'

const CouponIndexPage = ({ pageContext: { promoCode, channel, amount } }) => {
  const { updateBanner } = useContext(StoreContext)

  useEffect(() => {
    updateBanner(promoCode, channel, amount)

    // map promoCode to path
    const { [promoCode]: pathFromPromoCode } = {
      CHEER: `/frame/?utm_source=podcast_video&utm_medium=showrenew&utm_campaign=cheer_banner/`,
    }

    if (pathFromPromoCode) {
      navigate(pathFromPromoCode)
      return
    }

    // map channel to path
    const { [channel]: pathFromChannel } = {
      Toast: `?utm_source=offline&utm_medium=podcast&utm_campaign=${promoCode}/`,
      Influencer: `/calendar?utm_source=paidsocial&utm_medium=influencer&utm_campaign=${promoCode}/`,
      InfluencerMax: `/calendar-max?utm_source=paidsocial&utm_medium=influencer&utm_campaign=${promoCode}/`,
      InfluencerFrame: `/frame?utm_source=paidsocial&utm_medium=frameinfluencer&utm_campaign=${promoCode}/`,
      PodcastFrame: `/frame?utm_source=podcast&utm_medium=showrenew&utm_campaign=${promoCode}/`,
      PodcastBoth: `?utm_source=offline&utm_medium=podcast&utm_campaign=${promoCode}`,
      Today: `/frame?utm_source=offline&utm_medium=tv&utm_campaign=${promoCode}&utm_term=${promoCode}/`,
      Listening: `/calendar?utm_source=offline&utm_medium=podcast&utm_campaign=${promoCode}/`,
      GMA: `/calendar?utm_source=offline&utm_medium=tv&utm_campaign=${promoCode}/`,
      DirectMail: `/calendar?utm_source=offline&utm_medium=directmail&utm_campaign=${promoCode}/`,
      PR: `/calendar?utm_source=press&utm_medium=affiliate&utm_campaign=${promoCode}/`,
      TVFrame: `/frame?utm_source=tatari&utm_medium=tv&utm_campaign=frame&utm_term=${COUNTRIES[LOCALIZATION]?.abv}/`,
      TVCal: `${
        IS_FOREIGN_COUNTRY
          ? `/frame?utm_source=tatari&utm_medium=tv&utm_campaign=frame&utm_term=${COUNTRIES[LOCALIZATION]?.abv}/`
          : '/calendar?utm_source=tatari&utm_medium=tv&utm_campaign=cal/'
      }`,
      MIR: `?utm_source=monthreview&utm_medium=email&utm_campaign=november/`,
      InfluencerFrame2: `/frame-2?utm_source=paidsocial&utm_medium=frameinfluencer&utm_campaign=${promoCode}/`,
    }

    // if we didn't find a path mapped for a particular channel, do nothing
    if (!pathFromChannel) return

    // otherwise, navigate to the path
    navigate(pathFromChannel)
  }, [updateBanner, promoCode, channel, amount])

  return <IndexPage />
}

export default CouponIndexPage

CouponIndexPage.propTypes = {
  pageContext: PropTypes.object,
}
